var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "me-publish" },
    [
      _c("Header", [
        _c("i", {
          staticClass: "me-icon-back",
          attrs: { slot: "left-one" },
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          },
          slot: "left-one"
        }),
        _c("span", {
          attrs: { slot: "center" },
          domProps: {
            innerHTML: _vm._s(
              _vm.apiType === "enterprise"
                ? "<i class=me-icon-company />"
                : _vm.apiType === "group"
                ? "<i class=me-icon-group />"
                : _vm.$t("publish")
            )
          },
          slot: "center"
        })
      ]),
      _c(
        "div",
        { staticClass: "me-publish__content" },
        [
          _c(
            "van-cell-group",
            [
              _c("van-field", {
                attrs: {
                  required: "",
                  placeholder: _vm.placeholder.title,
                  error: _vm.emptyTitle
                },
                model: {
                  value: _vm.form.iCanDo,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "iCanDo", $$v)
                  },
                  expression: "form.iCanDo"
                }
              }),
              _c("van-field", {
                attrs: {
                  rows: "3",
                  autosize: "",
                  type: "textarea",
                  maxlength: "200",
                  placeholder: _vm.placeholder.desc,
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.form.detail,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "detail", $$v)
                  },
                  expression: "form.detail"
                }
              }),
              _vm.apiType === "service"
                ? _c("van-field", {
                    attrs: {
                      label: _vm.placeholder.price,
                      "label-width": "30px",
                      "input-align": "right"
                    },
                    model: {
                      value: _vm.form.price,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "price", $$v)
                      },
                      expression: "form.price"
                    }
                  })
                : _vm._e(),
              _c(
                "van-field",
                {
                  attrs: {
                    required: "",
                    disabled: "",
                    "input-align": "right",
                    error: _vm.emptyCate
                  },
                  on: {
                    click: function($event) {
                      return _vm.handlerToChosePage({
                        name: "selectCategory",
                        query: { redirect: _vm.$route.name }
                      })
                    }
                  },
                  model: {
                    value: _vm.categorysText,
                    callback: function($$v) {
                      _vm.categorysText = $$v
                    },
                    expression: "categorysText"
                  }
                },
                [
                  _c("i", {
                    staticClass: "me-icon-menu",
                    attrs: { slot: "left-icon" },
                    slot: "left-icon"
                  }),
                  _c("i", {
                    staticClass: "me-icon-right",
                    attrs: { slot: "right-icon" },
                    slot: "right-icon"
                  })
                ]
              ),
              _vm.isGroup
                ? _c("van-field", {
                    attrs: {
                      label: _vm.$t("permissions"),
                      "input-align": "right",
                      "is-link": ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.handlerToChosePage({
                          name: "chatGroupInfo",
                          query: {
                            redirect: _vm.$route.name,
                            select: true,
                            type: _vm.createGroupObj.type
                          }
                        })
                      }
                    },
                    model: {
                      value: _vm.createGroupObj.name,
                      callback: function($$v) {
                        _vm.$set(_vm.createGroupObj, "name", $$v)
                      },
                      expression: "createGroupObj.name"
                    }
                  })
                : _vm._e(),
              _c(
                "van-field",
                {
                  attrs: {
                    disabled: "",
                    label: _vm.$t("tags"),
                    "input-align": "right",
                    "label-width": "30px"
                  },
                  on: {
                    click: function($event) {
                      return _vm.handlerToChosePage({
                        name: "tagInput",
                        query: { redirect: _vm.$route.name }
                      })
                    }
                  },
                  model: {
                    value: _vm.form.tags,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "tags", $$v)
                    },
                    expression: "form.tags"
                  }
                },
                [
                  _c("i", {
                    staticClass: "me-icon-right",
                    attrs: { slot: "right-icon" },
                    slot: "right-icon"
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "upload-btns" },
            [
              _c("van-uploader", {
                attrs: {
                  "after-read": _vm.afterRead,
                  "max-count": _vm.type === "group" ? 1 : 5,
                  "preview-size": "78.5px",
                  "preview-image": ""
                },
                on: { delete: _vm.handlerRemoveImg },
                model: {
                  value: _vm.tempImgs,
                  callback: function($$v) {
                    _vm.tempImgs = $$v
                  },
                  expression: "tempImgs"
                }
              })
            ],
            1
          ),
          _c(
            "ul",
            { staticClass: "upload-files" },
            [
              _vm._l(_vm.tempFiles, function(item, index) {
                return _c(
                  "li",
                  { key: index, staticClass: "upload-files__item" },
                  [
                    _c("div", { staticClass: "upload-files__item--icon" }, [
                      _c("i", { staticClass: "me-icon-file" }),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            item.name.slice(
                              item.name.indexOf(".") + 1,
                              item.name.length
                            )
                          )
                        )
                      ])
                    ]),
                    _c("p", [_vm._v(_vm._s(item.name))]),
                    _c("i", {
                      staticClass: "me-icon-delete btnIcon",
                      on: {
                        click: function($event) {
                          return _vm.handlerRemove(index, item.id)
                        }
                      }
                    })
                  ]
                )
              }),
              _vm._l(_vm.tempVideos, function(item, index) {
                return _c(
                  "li",
                  {
                    key: "video-" + index,
                    staticClass: "upload-files__item two"
                  },
                  [
                    _c("video", {
                      staticClass: "upload-files__video",
                      attrs: { src: item.path }
                    }),
                    _c("i", {
                      staticClass: "me-icon-delete btnIcon",
                      on: {
                        click: function($event) {
                          return _vm.handlerRemoveVideo(index, item.id)
                        }
                      }
                    })
                  ]
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c("div", { staticClass: "me-publish__btn" }, [
        _c(
          "div",
          { staticClass: "submit" },
          [
            _c("van-button", {
              attrs: {
                color: "#33cc66",
                type: "primary",
                disabled: _vm.loading,
                loading: _vm.loading,
                round: "",
                icon: "success"
              },
              on: {
                click: function($event) {
                  _vm.id ? _vm.handlerUpDetail() : _vm.handlerSubmitForm()
                }
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }