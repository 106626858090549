<template>
  <div class="me-publish">
    <Header>
      <i slot="left-one" class="me-icon-back" @click="$router.go(-1)"></i>
      <span slot="center" v-html="apiType === 'enterprise'? '<i class=me-icon-company />' : apiType ==='group'? '<i class=me-icon-group />': $t('publish')"></span>
    </Header>
    <div class="me-publish__content">
      <van-cell-group>
        <van-field v-model="form.iCanDo" required :placeholder="placeholder.title" :error="emptyTitle"/>
        <van-field
          v-model="form.detail"
          rows="3"
          autosize
          type="textarea"
          maxlength="200"
          :placeholder="placeholder.desc"
          show-word-limit
        />
        <van-field
        v-if="apiType ==='service'"
          v-model="form.price"
          :label="placeholder.price"
          label-width="30px"
          input-align="right"
        />
        <van-field
          required
          disabled
          input-align="right"
          v-model="categorysText"
          :error="emptyCate"
          @click="handlerToChosePage({name:'selectCategory',query:{redirect: $route.name}})"
        >
          <i slot="left-icon" class="me-icon-menu"></i>
          <i slot="right-icon" class="me-icon-right"></i>
        </van-field>
        <van-field 
          :label="$t('permissions')" 
          v-if="isGroup" 
          v-model="createGroupObj.name" 
          input-align="right"
          @click="handlerToChosePage({name:'chatGroupInfo',query:{redirect: $route.name,select:true,type:createGroupObj.type}})" 
          is-link></van-field>

        <van-field
          disabled
          v-model="form.tags"
          :label="$t('tags')"
          input-align="right"
          label-width="30px"
          @click="handlerToChosePage({name:'tagInput',query:{redirect:$route.name}})"
        >
          <i slot="right-icon" class="me-icon-right"></i>
        </van-field>
      </van-cell-group>
      <div class="upload-btns">
        <van-uploader
          v-model="tempImgs"
          :after-read="afterRead"
          :max-count="type === 'group' ? 1 : 5"
          preview-size="78.5px"
          preview-image
          @delete="handlerRemoveImg"
        ></van-uploader>
      </div>
      <ul class="upload-files">
        <li class="upload-files__item" v-for="(item, index) in tempFiles" :key="index">
          <div class="upload-files__item--icon">
            <i class="me-icon-file"></i>
          <span>{{item.name.slice(item.name.indexOf('.')+1,item.name.length)}}</span>
          </div>
          <p>{{item.name}}</p>
          <i class="me-icon-delete btnIcon" @click="handlerRemove(index,item.id)"></i>
        </li>
        <li class="upload-files__item two" v-for="(item, index) in tempVideos" :key="'video-'+index">
          <video class="upload-files__video" :src="item.path"></video>
          <i class="me-icon-delete btnIcon" @click="handlerRemoveVideo(index,item.id)"></i>
        </li>
      </ul>
    </div>
    <div class="me-publish__btn">
      <div class="submit">
        <van-button
          color="#33cc66"
          type="primary"
          :disabled="loading"
          :loading="loading"
          @click="id ? handlerUpDetail() : handlerSubmitForm()"
          round
          icon="success"
        ></van-button>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/business/h5/header";
import { getUploadToken } from "@/api/qiniu";
import { env, parseTime } from "@/utils";
import * as qiniu from "qiniu-js";
import { clearPublishCatch,formartImageUrl } from "@/utils/help";
import { publishService,getDetailById,upDetail,getCategories } from "@/api/webApi";
export default {
  name: "publish",
  data() {
    return {
      form: {
        iCanDo: "",
        detail: "",
        price: "",
        tags: ""
      },
      curCategoryId: "",
      categorysText: "",
      imageData: [],
      tempImgs: [],
      loading: false,
      isGroup:false,
      placeholder:{},
      createGroupObj:{},
      apiType:'helogig',
      id:'',
      loginUser:{},
      tempFiles:[],
      tempVideos:[],
      language:'',
      categories:[],
      type:'',
      emptyTitle:false,
      emptyCate:false
    };
  },
  components: {
    Header
  },
  watch: {
    "form.iCanDo": {
      handler(newly) {
        if(!newly) this.emptyTitle = true
         else this.emptyTitle = false
        localStorage.setItem("publish-title", newly);
      },
      deep: true
    },
    "form.detail": {
      handler(newly) {
        localStorage.setItem("publish-content", newly);
      },
      deep: true
    },
    "form.price": {
      handler(newly) {
        localStorage.setItem("publish-price", newly);
      },
      deep: true
    },
    categorysText:{
      handler(val){
        if(!val) this.emptyCate = true
        else this.emptyCate = false
      },deep:true
    }

  },
  async created() {
      this.catchFormData()
      this.handlerinitBaseInfo()
      this.loginUser = JSON.parse(localStorage.getItem('userinfo') || {})
    if(this.apiType ==='group'){
      const groupStr = localStorage.getItem('createGroupType')
      this.createGroupObj = groupStr ? JSON.parse(groupStr) : {}
      if(!Object.keys(this.createGroupObj).length)
        this.createGroupObj = {
          type:'1',
          name:this.$t('groupType')['1'].name
        }
    }
    this.language = localStorage.getItem("langCode") || 1
    this.id = this.$route.query.id
    

    if(this.id){
      await this.handlerGetCategories()
      this.handlerGetDetail()
    }
    
      
  },
  methods: {
    handlerToChosePage(route){      
      if(this.imageData.length){
         localStorage.setItem('publish-imageData',JSON.stringify(this.imageData))
         let tmp = this.imageData.map(item=>{
           return {
             ...item,
           url:item.path
           }
         })
         localStorage.setItem('publish-tempImgs',JSON.stringify(tmp))
      }
      this.$router.push(route)
    },
    handlerRemoveImg(file,detail){
      this.imageData.splice(detail.index,1)      
    },
    async handlerGetCategories() {
      let params = {
        language: this.language
      };
      const result = await getCategories(params);
      if (result) {
        this.categories = result.data.data.map(item => {      
          return {
            text: item.category_name,
            value: item.category_id
          };
        });
      }
    },
    handlerGetDetail(){
      let type = this.$route.query.type
      this.placeholder = this.initplaceholder(type)
      switch (type) {
        case 'service':
          this.handlerGetServiceDetail()
          break;      
        case 'group':
          this.handlerGetGroupDetail()
          break;
        case 'company':
          this.handlerGetCompanyDetail()
          break
      }
    },
    handlerUpDetail(){
      let type = this.$route.query.type
      this.loading = !this.loading
      if(!this.$store.state.selectedCategories.length){
        this.emptyCate = true
        return
      }
      switch (type) {
        case 'service':
          this.handlerUpdateServiceDetail()
          break;      
        case 'group':
          this.handlerUpdateGroupDetail()
          break;
        case 'company':
          this.handlerUpdateCompanyDetail()
          break
      }
    },
    async handlerUpdateServiceDetail(){
      const upType = 'helogig'
      const params = {
        ...this.form,
        language:this.language,
        userId:this.loginUser.id,
        categoryId: this.$store.state.selectedCategories
            .map(item => item.value)
            .join(","),
        ...this.handlerFormartAttment()
      }
      const result = await upDetail(params,upType,this.id)
      this.handlerSuccessTip(result)      
    },
    handlerFormartAttment(){
      return {
        attachmentData:JSON.stringify(this.tempFiles[0]),
        videoData:JSON.stringify(this.tempVideos),
        imageData:JSON.stringify(this.imageData)
      }
    },
    async handlerUpdateGroupDetail(){
      const upType = 'group'
      const params = {
        groupName:this.form.iCanDo,
        groupDetail:this.form.detail,
        tags:this.form.tags,
        accessPattern:this.createGroupObj.type,
        language:this.language,
        userId:this.loginUser.id,
        categoryId: this.$store.state.selectedCategories
            .map(item => item.value)
            .join(","),
        ...this.handlerFormartAttment()
      }
      console.log(params,'/params update');
      
      const result = await upDetail(params,upType,this.id)
      this.handlerSuccessTip(result)  
    },
    async handlerUpdateCompanyDetail(){
      const upType = 'enterprise'
      const params = {
        enterpriseName:this.form.iCanDo,
        enterpriseInfo:this.form.detail,
        tags:this.form.tags,
        language:this.language,
        userId:this.loginUser.id,
        categoryId: this.$store.state.selectedCategories
            .map(item => item.value)
            .join(","),
        ...this.handlerFormartAttment()
      }
      const result = await upDetail(params,upType,this.id)
      this.handlerSuccessTip(result)  
    },
    handlerSuccessTip(result){
      if(result && result.code ===200){
        localStorage.removeItem('changeSelecte')
        localStorage.removeItem('changeTag')
        clearPublishCatch()
        this.$toast.success() 
        this.$router.push({name:'my',params:{active:this.$route.query.active}})  
      }      
      else this.$toast.fail()
      this.loading = !this.loading
    },
    async handlerGetServiceDetail(){
      const type = 'ServiceJobDetail'
      const params = {
        language:this.language,
        userId:this.loginUser.id,
        id:this.id,
        type:0
      }
      const result = await getDetailById(params,type)
      if(result && result.data){
        const data = result.data[0]
        const {i_can_do,detail,price} = data        
        
        this.form = {
          iCanDo: i_can_do,
          detail: detail.replace(/<\/?[^>]*>/g,''),
          price: price
        }
         this.handlerFormartMediaData(data,type,true)        
      }  
      
    },
    async handlerGetGroupDetail(){
       const type = 'GroupDetail'
      const params = {
        language:this.language,
        userId:this.loginUser.id,
        groupId:this.id
      }
      const result = await getDetailById(params,type)
      if(result && result.data){
        const data = result.data[0]        
        const {group_name,group_info,access_pattern} = data          
        this.form = {
          iCanDo: group_name,
          detail: group_info.replace(/<\/?[^>]*>/g,'')
        }
        if(!localStorage.getItem('createGroupType'))
          this.createGroupObj = {
            type:access_pattern,
            name:this.$t('groupType')[access_pattern].name
          }
        this.handlerFormartMediaData(data,type)
        
      }
    },
   async handlerGetCompanyDetail(){
       const type = 'EnterpriseDetail'
      const params = {
        language:this.language,
        userId:this.loginUser.id,
        enterpriseId:this.id
      }
      const result = await getDetailById(params,type)
      if(result && result.data){
        const data = result.data[0]
        const {enterprise_name,enterprise_info} = data        
        
        this.form = {
          iCanDo: enterprise_name,
          detail: enterprise_info.replace(/<\/?[^>]*>/g,''),
        }
        this.handlerFormartMediaData(data,type)        
      }
    },
    handlerFormartMediaData(data,type,isExit){      
      let cateGorisKey = ''
      let tagKey = ''
      switch(type){
        case 'ServiceJobDetail':
          cateGorisKey = 'category_id'
          tagKey = 'tags'
          break
        case 'GroupDetail':
          cateGorisKey = 'group_category',
          tagKey = 'group_tag'
          break;
        case 'EnterpriseDetail':
          cateGorisKey = 'enterprise_category'
          tagKey = 'enterprise_tag'
          break
      }
      const categoris =  []
      data[cateGorisKey].split(',').map(item=>{
        this.categories.map(el=>{
          if(el.value == item){
            categoris.push({...el,selected:true})
          }
        })
      })
      const init = localStorage.getItem('changeSelecte') ? false : true
      if (init){       
        this.categorysText = categoris.map(item=>item.text).join(',')      
        this.$store.dispatch('commitSelectedCategories',categoris)
      }
      const initTag = localStorage.getItem('changeTag') ? false : true
      if(initTag){
        this.form.tags = data[tagKey]
        this.$store.dispatch("comminTags", data[tagKey].split(','));
        localStorage.setItem("publish-tags", data[tagKey]);       
      }else{
        this.form.tags = this.$store.state.tags.join(',')
      }
      this.tempFiles = data.mediaDatas.filter(item=>item.type === 4)
      this.tempVideos = data.mediaDatas.filter(item=>item.type === 1)

       const catchimgStr = localStorage.getItem('publish-imageData')
      if(isExit && catchimgStr){
        let catchImg = JSON.parse(localStorage.getItem('publish-imageData') || {})
        this.tempImgs = this.imageData = catchImg.map(el=>{
          return {url:formartImageUrl(el.path),...el,isImage:true}
        })
      }else
         this.tempImgs = this.imageData = data.mediaDatas.filter(item=> item.type === 3).map(item=>{
          return  {url:formartImageUrl(item.path),...item,isImage:true}
        })
      
    },
    handlerRemove(index){
      this.tempFiles.splice(index,1)
    },
    handlerRemoveVideo(index){
      this.tempVideos.splice(index,1)
    },
    catchFormData(){
        this.form.iCanDo = localStorage.getItem("publish-title") || "";
        this.form.detail = localStorage.getItem("publish-content") || "";
        this.form.price = localStorage.getItem("publish-price") || "";
        this.categorysText = this.$store.state.selectedCategories.length
          ? localStorage.getItem("publish-categorys") || ""
          : "";
        this.form.tags = this.$store.state.tags.length
          ? localStorage.getItem("publish-tags") || ""
          : "";
        let imageData = localStorage.getItem('publish-imageData') || ''
        let tempImgs = localStorage.getItem('publish-tempImgs')
        if(imageData) this.imageData = JSON.parse(imageData)
        if(tempImgs) this.tempImgs = JSON.parse(tempImgs)
    },
    handlerinitBaseInfo(){
      const type = this.type = this.$route.query.type || 'service'
      this.placeholder = this.initplaceholder(type)
    },
    initplaceholder(type){
      let title = ''
      let price = ''
      let desc = ''
      let tags = ''
      switch(type){
        case 'service':
          title = this.$t('title')
          desc = this.$t('desc')
          price = this.$t('price')
          tags = this.$t('tags')
          break;
        case 'group':
          title = this.$t('group')
          desc = this.$t('desc')
          this.isGroup = true
          this.apiType = 'group'
          break;

        case 'company':
          title = this.$t('company')
          desc = this.$t('desc')
          this.apiType = 'enterprise'

          break
      }
      return {
        title,
        price,
        desc,
        tags

      }
    },
    _checkForm() {
      if(!this.form.iCanDo) this.emptyTitle = true
      if(!this.categorysText) this.emptyCate = true
      if (!this.form.iCanDo || !this.categorysText) {
        return false;
      }
      this.emptyTitle = this.emptyCate = false
      return true;
    },
    async handlerSubmitForm() {
      if (this._checkForm()) {
        let params = {
          ...this.form,
          categoryId: this.$store.state.selectedCategories
            .map(item => item.value)
            .join(","),
          userId: this.$store.state.User.userinfo.id,
          language: localStorage.getItem("langCode") || 1
        };
        if(this.apiType === 'enterprise'){
          params.enterpriseName = this.form.iCanDo
          params.enterpriseInfo = this.form.detail
          delete params.iCanDo
          delete params.detail
          delete params.price
        }
        if(this.apiType ==='group'){
          params.groupName = this.form.iCanDo
          params.groupDetail = this.form.detail
          params.accessPattern = this.createGroupObj.type
          delete params.iCanDo
          delete params.detail
          delete params.price
        }
        if (this.imageData.length) {
          
          params.imageData = JSON.stringify(this.imageData);
        }
        this.loading = !this.loading;
        const result = await publishService(params,this.apiType).catch(e => {});
        this.loading = !this.loading;
        if (result && result.data && result.data.length) {
          clearPublishCatch();
          this.$toast.success();
          this.$router.push({ name: "my",params:{active:this.$route.query.active} });
        } else {
          this.$toast.fail();
        }
      } else {
        console.log("some error");
      }
    },
    async afterRead(file) {     
      file.status = 'uploading'; 
      file.message = '上传中...';
      console.log(this.tempImgs,'temp///');
      
      // 一次性多选时，上传七牛云解析出错
      await this.handlerUploadFileToQiniu(file, "imageData");
    },
    async handlerUploadFileToQiniu(files, field) {
      this.loading = true
      let file = files.file;
      let oldName = file.name;
      let type = file.type;    

      const isvideo = ["audio", "video"];
      const bucket = !env('BUCKET') ? 'tfile': isvideo.some(val => file.type.indexOf(val) !== -1)
          ? "video"
          : "imfile";
      const config = await getUploadToken(file.name, bucket);

      const { randomFileName, token } = config.data;
      const [year, month] = parseTime(new Date(), "{y}-{m}").split("-");

      const observable = qiniu.upload(
        file,
        `${year}/${month}/${randomFileName}`,
        token,
        {
          fname: file.name
        },
        {
          useCdnDomain: true,
          region: qiniu.region.z2
        }
      );
      let item = {
        name: oldName,
        type,
        progress: 0,
        path: "",
        size: file.size
      };      
      observable.subscribe({
        next: uploadEvent => {
          item.progress = uploadEvent.total.percent.toFixed(2);
        },
        error: error => {
          console.log("error", error);
          this.loading = false
          file.status = 'failed'
        },
        complete: res => {
          file.status = 'success'
          const baseurl = {
            tfile: env("UPLOAD_IMFILE"),
            video: env("UPLOAD_VIDEO"),
            imfile: env("UPLOAD_IMFILE")
          };
          item.path = `${baseurl[bucket]}/${res.key}`;
          this[field].push(item);
          this.loading = false
          console.log(this[field],'/adfad success');
          
        }
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';
+b(upload-files){
  
  display flex;
  flex-flow:row;
  +e(video){
    width:80px;
    height:80px;
    background:#e5e5e5;
    font-family: 'iconfont'; 
    position relative
    &::before{
      position: absolute;
      content:'e61c'; 
      color:#ffffff;
      font-size:22px; 
    }
  }

  +e(item){
    width:100%;
    padding:0.25rem .9rem;
    border-top:1px dashed #e5e5e5;
    border-radius:.35rem;
    position relative
    display:flex;
    // align-items:center;
    // justify-content:center;
    color:#1989fa;
    &.two{
      justify-content space-between;
    }
    +m(icon){
      i{
        &::before{
          font-size:50px;
        }
      }
      span{
        position: absolute;
        left:1.4rem;
        top:1.7rem
        // font-weight:bold;
        text-transform:Uppercase
      }
      
    }
    p{
        font-size:14px;
        color:#333333;
        margin-left:.5rem;
        flex:1
      }
      .btnIcon{
        font-size:22px;
        color:red
      }
    
   
  }
}
+b(me-publish) {
  min-height: 80vh;
  background: #ffffff;

  +e(content) {
    margin: 55px 0;
    .van-cell-group{
      .van-field--error{
        &::after{
          border-color red
        }
      }
    }

    +b(upload-btns) {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      padding: 0.9rem;

      [class^='me-icon'] {
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 30px;
        padding: 0.5rem;
        background: #f5f5f5;
        margin: 0 0.5rem 0 0;
        color: #a2a2a2;
        border-radius: 0.3rem;
      }
    }
  }

  +e(btn) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #f5f5f5;

    .submit {
      display: flex;
      padding: 0.5rem 0.9rem;

      button {
        flex: 1;
        // border-radius: 5rem;
        font-size: 22px;
        .van-loading{
          height auto
          padding 0
        }
      }
    }
  }
}
</style>